import { HttpClient, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { Subject } from "rxjs";

import { AppMenuComponent } from "../components/app-menu/app-menu.component";
import {
  NotHome,
  Project,
  ProjectSettings,
  Visitation,
} from "../interfaces/api-heatbrain";
import {
  BuildingYearMapColors,
  BuildingYearRangeColor,
} from "../interfaces/building-year-map-colors";
import { MessagebirdMessage } from "../interfaces/message_bird/message";
import { MessagebirdTicket } from "../interfaces/message_bird/ticket";
import { ProjectUser } from "../interfaces/project-user";
import { AcquisitionStats } from "../interfaces/scan/acquisition-stats";
import { User } from "../interfaces/user";
import { ApiHeatbrainService } from "./api-heatbrain/api-heatbrain.service";
import { CONTENT_TYPE, RESPONSE_TYPE } from "./api-heatbrain/request";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  selected_project_obs: Subject<Project | null> = new Subject<Project | null>();
  downloadLoading = { info: false, followup: false, infoSobolt: false };
  addressGeojson: any | null = null;
  addresses: any[] | null = null;
  signup_types: any[] | null = null;
  signup_texts: { [key: string]: string } | null = null;
  signup_questions: any[] | null = null;
  all_signup_questions: any[] | null = null;
  visitation_details: any[] | null = null;

  public projects: Project[] = [];
  public selected_project: Project | null = null;
  public loadingProject: boolean = false;

  constructor(
    public apiHeatbrainService: ApiHeatbrainService,
    public appMenu: AppMenuComponent,
    private http: HttpClient /* private store: Store<{ user$: User }> */,
  ) {}

  public emitSelectedProject() {
    this.addressGeojson = null;
    this.signup_types = null;
    this.addresses = null;
    this.signup_texts = null;
    this.signup_questions = null;
    this.all_signup_questions = null;
    this.visitation_details = null;
    this.selected_project_obs.next(this.selected_project);
  }

  public on_error_get_projects(error: HttpErrorResponse): void {
    console.error("An error occured when fetching all projects: ", error);
    alert(`Could not get all projects. ${error.error.detail}`);
  }

  public alert_on_error(error: HttpErrorResponse): void {
    alert(`${error.error.detail}`);
  }

  public custom_error(error: HttpErrorResponse): void {
    alert(
      "Sorry, er ging iets verkeerd. Probeer het nog eens of plan je afspraak telefonisch via 010 - 899 55 10.",
    );
  }

  public file_upload_error(error: HttpErrorResponse): void {
    if (error.status === 413) {
      alert("Maximale bestandsgrootte is 1 MB");
    } else {
      alert(`${error.error.detail[0].msg}`);
    }
  }

  public console_on_error(error: HttpErrorResponse): void {
    console.log(`${error.error.detail}`);
  }

  public get_projects(): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["get-projects"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    this.apiHeatbrainService
      .get<Project[]>(req, this.on_error_get_projects)
      .then((projects) => {
        this.projects = projects;
        this._sortProjectsOnProperty("name", "asc");
      })
      .catch(() => (this.projects = []));
  }

  public get_scan_projects(): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["get-projects"]);
    req.setQueryParameters({ project_type: "scan" });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.apiHeatbrainService
      .get<Project[]>(req, this.on_error_get_projects)
      .then((projects: Project[]) => {
        this.projects = projects;
        this._sortProjectsOnProperty("name", "asc");
      })
      .catch(() => (this.projects = []));
  }

  public get_energieklus_projects(): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["get-projects"]);
    req.setQueryParameters({ project_type: this.appMenu.currentApp });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.apiHeatbrainService
      .get<Project[]>(req, this.on_error_get_projects)
      .then((projects: Project[]) => {
        this.projects = projects;
        this._sortProjectsOnProperty("name", "asc");
      })
      .catch(() => (this.projects = []));
  }

  public get_energieklus_projects_dropdown(): Promise<Project[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["get-projects"]);
    req.setQueryParameters({ project_type: this.appMenu.currentApp });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<Project[]>(req, this.on_error_get_projects);
  }

  public get_energieklus_feed_ticket_types(): Promise<any[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "data", "ticket-types"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<any[]>(req, this.alert_on_error);
  }

  public get_energieklus_feed_ticket_statuses(): Promise<any[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "data", "ticket-statuses"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<any[]>(req, this.alert_on_error);
  }

  public get_active_energieklus_projects(): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["get-projects"]);
    req.setQueryParameters({ project_type: this.appMenu.currentApp });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.apiHeatbrainService
      .get<Project[]>(req, this.on_error_get_projects)
      .then((projects: Project[]) => {
        this.projects = projects;
        this._sortProjectsOnProperty("name", "asc");
      })
      .catch(() => (this.projects = []));
  }

  public set_selected_energieklus_project_by_id(project_id): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["get-projects"]);
    req.setQueryParameters({ project_type: this.appMenu.currentApp });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.apiHeatbrainService
      .get<Project[]>(req, this.on_error_get_projects)
      .then((projects: Project[]) => {
        const proj = projects.filter((p) => p.id === project_id);
        if (proj.length === 1) {
          this.selected_project = proj[0];
          this.emitSelectedProject();
        } else {
          return Error(
            "Tried to set project but got a number of projects unequal to 1",
          );
        }
      });
  }

  public get_cams(): Promise<any[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["cameras"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<any[]>(req, this.console_on_error);
  }

  public get_assignees(): Promise<User[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["assignees"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<User[]>(req, this.console_on_error);
  }

  public get_users_for_project(project_id: string): Promise<ProjectUser[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project_id, "users"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<ProjectUser[]>(req, this.console_on_error);
  }

  public upload_bucket_to_project_bucket(
    uploadBucket: string,
    projectBucket: string,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["upload-bucket-to-project-bucket"]);
    const reqBody = {
      upload_bucket: uploadBucket,
      project_bucket: projectBucket,
    };
    req.setRequestBody(reqBody);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public get_klussers_for_project(project_id: string): Promise<any[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project_id, "klussers"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<any[]>(req, this.console_on_error);
  }

  public get_muns(): Promise<any[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["municipalities"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<any[]>(req, this.console_on_error);
  }

  public get_project_settings(project_id: string): Promise<ProjectSettings> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["projects", project_id, "settings"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<ProjectSettings>(req, this.console_on_error);
  }

  public get_project_with_id(project_id: string): Promise<Project> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["projects", project_id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<Project>(req, this.console_on_error);
  }

  public get_n_available_photos(project_id: string): Promise<{ [key: string]: any }> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["photos", project_id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<{ [key: string]: any }>(
      req,
      this.console_on_error,
    );
  }

  public get_analyses_to_go(project_id: string, assignee: string): Promise<number[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["analyses-to-go", project_id, assignee]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<number[]>(req, this.console_on_error);
  }

  public get_analyses_all(project_id: string): Promise<string> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["analyses"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<string>(req, this.console_on_error);
  }

  public get_addresses_with_acquired_date(project_id: string): Promise<any[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["addresses-acquired", project_id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<any[]>(req, this.console_on_error);
  }

  public get_addresses_geojson(project_id: string): Promise<string> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["addresses", project_id, "geometric.geojson"]);
    req.setQueryParameters({ crs: 4326 });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<string>(req, this.console_on_error);
  }

  public async get_energieklus_addresses(
    project_id: string,
  ): Promise<any[]> {
    if (
      this.selected_project &&
      this.selected_project.id == project_id &&
      this.addresses != null
    ) {
      return this.addresses;
    }
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project_id, "addresses"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    this.addresses = await this.apiHeatbrainService.get<any[]>(
      req,
      this.console_on_error,
    );
    return this.addresses;
  }

  public async get_filtered_energieklus_addresses(
    project_id: string,
    postcode: string,
    huisnummer: string,
    huisletter: string,
    toevoeging: string,
    visited: boolean = false,
  ): Promise<any> {
    if (
      this.selected_project &&
      this.selected_project.id == project_id
    ) {
      let req = this.apiHeatbrainService.requestFactory();
      req.setUrlParameters(["energieklus", "projects", project_id, "filtered_addresses"]);
      req.setQueryParameters({ 
        postcode: postcode, 
        huisnummer: huisnummer,
        huisletter: huisletter,
        toevoeging: toevoeging,
        visited: visited.toString() });
      req.setHeaders(CONTENT_TYPE.JSON);
      this.addresses = await this.apiHeatbrainService.get<any[]>(
        req,
        this.console_on_error,
      );
      return this.addresses;
    } else {
      return [];
    }
  }

  public async get_energieklus_addresses_geojson(project_id: string): Promise<any> {
    if (
      this.selected_project &&
      this.selected_project.id == project_id &&
      this.addressGeojson != null
    ) {
      return this.addressGeojson;
    }
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "energieklus",
      "projects",
      project_id,
      "addresses",
      "geometric.geojson",
    ]);
    req.setQueryParameters({ crs: 4326 });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.addressGeojson = await this.apiHeatbrainService
      .get<any>(req, this.alert_on_error)
      .catch((err) => {
        return null;
      });
    return this.addressGeojson;
  }

  public get_logo(project_id: string): Promise<string[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["logos", project_id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<string[]>(req, this.console_on_error);
  }

  public get_municipality_logo(project_id: string): Promise<Blob> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["municipality_logo", project_id]);
    req.setHeaders(CONTENT_TYPE.JSON, RESPONSE_TYPE.BLOB);
    return this.apiHeatbrainService.get_public(req, this.console_on_error);
  }

  public get_delivery_csv(project: Project): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["delivery-lists", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, project.slug + ".csv");
    });
  }

  public get_printerpro_csv(project: Project): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["printerpro-list", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, project.slug + ".csv");
    });
  }

  public get_opt_access_codes_csv(project: Project): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["opt-access-codes-csv", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, project.slug + "_access-codes.csv");
    });
  }

  public get_reminder_signup_municipal_csv(project: Project): void {
    this.downloadLoading["info"] = true;
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["reminder-signup-municipal-csv", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, project.slug + "-informatie_van_gemeente.csv");
      this.downloadLoading["info"] = false;
    });
  }

  public get_reminder_signup_sobolt_csv(project: Project): void {
    this.downloadLoading["infoSobolt"] = true;
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["reminder-signup-sobolt-csv", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, project.slug + "-informatie_van_sobolt.csv");
      this.downloadLoading["infoSobolt"] = false;
    });
  }

  public get_additional_visit_signup_csv(project: Project): void {
    this.downloadLoading["followup"] = true;
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["additional-visit-signup-csv", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, project.slug + "-vervolgafspraak_gemeente.csv");
      this.downloadLoading["followup"] = false;
    });
  }

  public authorise_building_owner(
    postalCode: string,
    accessCode: string,
    projectType: string,
  ): Promise<{ [key: string]: any }> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "opt-authorise-building-owner",
      accessCode,
      postalCode,
      projectType,
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    // return this.apiHeatbrainService.get_public<any[]>(req, this.alert_on_error);
    return this.http.get<{ [key: string]: any }>(req.getUrl()).toPromise();
  }

  public send_opt_confirmation_email(
    address_id: string,
    email: string,
  ): Promise<{ [key: string]: string }> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["send-opt-email-confirmation", address_id, email]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.http.get<{ [key: string]: string }>(req.getUrl()).toPromise();
  }

  public set_opt_state(
    address_id: string,
    opt_state: string,
    email: string,
  ): Promise<{ [key: string]: any }> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["set-opt-state", address_id]);
    req.setQueryParameters({
      address_id: address_id,
      opt_state: opt_state,
      email: email,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put_public<{ [key: string]: any }>(
      req,
      this.console_on_error,
    );
  }

  public set_opt_capacity(
    project: Project,
    opt_capacity: string,
  ): Promise<{ [key: string]: any }> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["set-opt-capacity", project.id]);
    req.setQueryParameters({
      project_id: project.id,
      opt_capacity: opt_capacity,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put<{ [key: string]: any }>(
      req,
      this.alert_on_error,
    );
  }

  public set_opt_deadline(
    project: Project,
    opt_deadline: string,
  ): Promise<{ [key: string]: any }> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["set-opt-deadline", project.id]);
    req.setQueryParameters({
      project_id: project.id,
      opt_deadline: opt_deadline,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put<{ [key: string]: any }>(
      req,
      this.alert_on_error,
    );
  }

  public update_project_setting(
    project: Project,
    setting_name: string,
    value: any,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["projects", project.id, "settings", setting_name]);
    req.setQueryParameters({ value: value });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put(req, this.alert_on_error);
  }

  public drop_opt_outs(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["projects", project.id, "addresses", "opt-outs"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.delete(req, this.alert_on_error);
  }

  public get_project_csv(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["neighbourhood-csv", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public upload_project_csv_to_bucket(
    project: Project,
  ): Promise<{ [key: string]: any }> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["neighbourhood-csv-bucket", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public get_multiproject_csv(projects: Project[]): Promise<any> {
    const projectIds: string[] = projects.map((project) => project.id);
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["multiproject-neighbourhood-csv"]);
    req.setArrayQueryParameter("project_ids", projectIds);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_project_stats(project: Project): Promise<String> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["neighbourhood-stats", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_multiproject_stats(projects: Project[]): Promise<String> {
    const projectIds: string[] = projects.map((project) => project.id);
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["multiproject-neighbourhood-stats"]);
    req.setArrayQueryParameter("project_ids", projectIds);
    req.setHeaders(CONTENT_TYPE.JSON);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public upload_multiproject_csv_to_bucket(projects: Project[]): Promise<any> {
    const projectIds: string[] = projects.map((project) => project.id);
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["multiproject-neighbourhood-csv-bucket"]);
    req.setArrayQueryParameter("project_ids", projectIds);
    req.setHeaders(CONTENT_TYPE.JSON);

    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public get_project_gpkg(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["neighbourhood-gpkg", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public upload_project_gpkg_to_bucket(
    project: Project,
  ): Promise<{ [key: string]: any }> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["neighbourhood-gpkg-bucket", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);

    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public get_multiproject_gpkg(projects: Project[]): Promise<Blob> {
    const projectIds: string[] = projects.map((project) => project.id);
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["multiproject-neighbourhood-gpkg"]);
    req.setArrayQueryParameter("project_ids", projectIds);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public upload_multiproject_gpkg_to_bucket(projects: Project[]): Promise<any> {
    const projectIds: string[] = projects.map((project) => project.id);
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["multiproject-neighbourhood-gpkg-bucket"]);
    req.setArrayQueryParameter("project_ids", projectIds);
    req.setHeaders(CONTENT_TYPE.JSON);

    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public get_neighbourhood_insulation_potential_map(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["projects", project.id, "potential-insulation-savings-map"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_multiproject_insulation_potential_map(projects: Project[]): Promise<any> {
    const projectIds: string[] = projects.map((project) => project.id);
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["multi-project", "potential-insulation-savings-map"]);
    req.setArrayQueryParameter("project_ids", projectIds);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_neighbourhood_building_year_map(
    project: Project,
    buildingYearColors: BuildingYearMapColors,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["projects", project.id, "building-year-map"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);
    const yearBoundaries: number[] = buildingYearColors.yearColors
      .map((el: BuildingYearRangeColor) => el.year_boundary)
      .filter((el: number) => el !== null && el !== undefined);
    const colors: string[] = buildingYearColors.yearColors.map(
      (el: BuildingYearRangeColor) => el.color,
    );
    req.setArrayQueryParameter("year_boundaries", yearBoundaries);
    req.setArrayQueryParameter("colors", colors, true);
    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_multiproject_building_year_map(
    projects: Project[],
    buildingYearColors: BuildingYearMapColors,
  ): Promise<any> {
    const projectIds: string[] = projects.map((project) => project.id);
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["multi-project", "building-year-map"]);
    req.setArrayQueryParameter("project_ids", projectIds);
    const yearBoundaries: number[] = buildingYearColors.yearColors
      .map((el: BuildingYearRangeColor) => el.year_boundary)
      .filter((el: number) => el !== null && el !== undefined);
    const colors: string[] = buildingYearColors.yearColors.map(
      (el: BuildingYearRangeColor) => el.color,
    );
    req.setArrayQueryParameter("year_boundaries", yearBoundaries, true);
    req.setArrayQueryParameter("colors", colors, true);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_project_wijk_insulation_potential_map(
    project: Project,
    insulation_type: string,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "projects",
      project.id,
      "wijk-potential-map",
      insulation_type,
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_multiproject_wijk_insulation_potential_map(
    insulationType: string,
    projects: Project[],
  ): Promise<any> {
    const projectIds: string[] = projects.map((project) => project.id);
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["multi-project", "wijk-potential-map", insulationType]);
    req.setArrayQueryParameter("project_ids", projectIds);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_multiproject_buurt_insulation_potential_map(
    insulationType: string,
    projects: Project[],
  ): Promise<any> {
    const projectIds: string[] = projects.map((project) => project.id);
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["multi-project", "buurt-potential-map", insulationType]);
    req.setArrayQueryParameter("project_ids", projectIds);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_project_buurt_insulation_potential_map(
    project: Project,
    insulation_type: string,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "projects",
      project.id,
      "buurt-potential-map",
      insulation_type,
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);

    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public on_error_create_project(error: HttpErrorResponse): void {
    alert(`Kon geen project aanmaken. ${error.error.detail}`);
  }

  public create_project(name: string, opt_type: string, project_type: string): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["create-project"]);
    req.setQueryParameters({
      name: name,
      opt: opt_type,
      project_type: project_type,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.apiHeatbrainService
      .post<Project>(req, this.on_error_create_project)
      .then((new_project) => {
        this.projects.push(new_project);
        this._sortProjectsOnProperty("name", "asc");
        this.selected_project = new_project;
      });
  }

  public safely_analyze(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["safely-analyze"]);
    req.setRequestBody({ project: project, station: { id: 215 } });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public upload_to_carto(project: Project): Promise<number> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["upload-current-addresses-to-carto"]);
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public sync_db_with_carto(project: Project): Promise<number> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["sync-db-addresses-with-carto"]);
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public force_analyze(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["force-analyze"]);
    req.setRequestBody({ project: project, station: { id: 215 } });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public generate_sample(project: Project, reportType: string, thermogram: File) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(thermogram);
    }).then((base64_thermogram) => {
      const req = this.apiHeatbrainService.requestFactory();
      req.setUrlParameters(["generate-sample"]);
      req.setRequestBody({
        project: project,
        thermogram: base64_thermogram,
        report_type: reportType,
      });
      req.setHeaders(CONTENT_TYPE.JSON, RESPONSE_TYPE.BLOB);
      return this.apiHeatbrainService.post(req, this.alert_on_error);
    });
  }

  public push_reports(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["upload-reports"]);
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public push_jsons(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["upload-jsons"]);
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public safely_generate(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["safely-generate"]);
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public force_generate(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["force-generate"]);
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public upload_reports(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["upload-reports"]);
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put(req, this.alert_on_error);
  }

  public generate_single(
    project: Project,
    analysis_id: string,
    error_callback: any,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["generate-single"]);
    req.setQueryParameters({ analysis_id: analysis_id });
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, error_callback);
  }

  public generate_single_local(project: Project, analysis_id: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["generate-single-local"]);
    req.setQueryParameters({ analysis_id: analysis_id });
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON, RESPONSE_TYPE.BLOB);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public delete_analyses(project: Project, analysis_ids: string[]): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["delete-analyses"]);
    req.setRequestBody({ analysis_ids, project });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public set_analyses_assignee(
    project: Project,
    analyses: string[],
    assignee: string,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["analyses", project.id]);
    req.setRequestBody({ analyses: analyses, assignee: assignee });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put(req, this.alert_on_error);
  }

  public set_project_finished(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["finish", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put(req, this.alert_on_error);
  }

  public set_project_archived(project: Project): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["archive", project.id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put(req, this.alert_on_error);
  }

  public on_error_update_project(error: HttpErrorResponse): void {
    console.error("An error occured when updating project a project: ", error);
    alert(`Could not update project. ${error.error.detail}`);
  }

  public update_project(project: Project): void {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["update-project"]);
    req.setRequestBody(project);
    req.setHeaders(CONTENT_TYPE.JSON);
    this.apiHeatbrainService
      .put<Project>(req, this.on_error_update_project)
      .then((new_project) => {
        this.projects = this.projects.filter((p) => p.id !== new_project.id);
        this.projects.push(new_project);
        this._sortProjectsOnProperty("name", "asc");
        this.selected_project = new_project;
      });
  }

  public put_file(
    endpoint: string,
    key: string,
    project: Project,
    fileToUpload: File,
  ): Promise<string> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([endpoint, project.id]);

    let obj = {};
    obj[key] = fileToUpload;
    req.setRequestBody(obj);
    req.setHeaders(CONTENT_TYPE.FORM);
    return this.apiHeatbrainService.put(req, this.file_upload_error);
  }

  public put_address_list(
    endpoint: string,
    key: string,
    project: Project,
    mun_codes: string[],
    replace: boolean,
    fileToUpload: File,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([endpoint, project.id]);
    req.setQueryParameters({ replace: replace.toString() });
    let obj = {};
    obj[key] = fileToUpload;
    obj["municipality_codes"] = mun_codes.join();
    req.setRequestBody(obj);
    req.setHeaders(CONTENT_TYPE.FORM);
    return this.apiHeatbrainService.put(req, this.alert_on_error);
  }

  public post_energieklus_visitations(project: Project, csvFile: File): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project.id, "visitations"]);
    let obj = {};
    obj["visitations_csv"] = csvFile;
    req.setRequestBody(obj);
    req.setHeaders(CONTENT_TYPE.FORM);
    return this.apiHeatbrainService.post(req, undefined);
  }

  public get_energieklus_visitations(project: Project): Promise<Visitation[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project.id, "visitations"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<Visitation[]>(req, this.alert_on_error);
  }

  public verify_email_and_or_phone_number(
    visitation_id: string,
    email: string = "",
    phone_number: string = "",
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "energieklus",
      "projects",
      "visitations",
      visitation_id,
      "masked",
    ]);
    req.setQueryParameters({
      email: email,
      phone_number: phone_number,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post_public<any>(req, this.alert_on_error);
  }

  public async get_energieklus_visitations_dashboard_info(
    project: Project,
  ): Promise<any[]> {
    if (this.selected_project.id == project.id && this.visitation_details != null) {
      return this.visitation_details;
    }
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "energieklus",
      "projects",
      project.id,
      "visitations",
      "details",
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    this.visitation_details = await this.apiHeatbrainService.get(
      req,
      this.alert_on_error,
    );
    return this.visitation_details;
  }

  public get_energieklus_project_regions(project: Project): Promise<any[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project.id, "regions_table"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_energieklus_project_sisa_export(
    project: Project,
    sisa_code: string,
    start_date: string,
    end_date: string,
  ) {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "energieklus",
      "projects",
      project.id,
      "sisa",
      sisa_code,
      start_date,
      end_date,
      "csv",
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);
    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, `sisa_${sisa_code}_${project.name}.csv`);
    });
  }

  public post_energieklus_project_regions(
    project: Project,
    csvFile: File,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project.id, "regions"]);
    let obj = {};
    obj["regions_csv"] = csvFile;
    req.setRequestBody(obj);
    req.setHeaders(CONTENT_TYPE.FORM);
    return this.apiHeatbrainService.post(req, undefined);
  }

  public get_timeslot_csv(project_id: string) {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project_id, "timeslots", "csv"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);
    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, `bezoekmomenten_template.csv`);
    });
  }

  public get_addresses_csv(project_id: string, csv_type: string) {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "energieklus",
      "projects",
      project_id,
      csv_type,
      "addresses",
      "csv",
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);
    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, `addresses_template.csv`);
    });
  }

  public get_region_csv(project_id: string) {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project_id, "regions", "csv"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setHeaders(undefined, RESPONSE_TYPE.BLOB);
    this.apiHeatbrainService.get(req, this.alert_on_error).then((data) => {
      saveAs(data, `regios_template.csv`);
    });
  }

  public get_energieklus_visitations_postcode_optcode(
    opt_code: string,
    postal_code: string,
    error_callback?: Function,
    action_id: string = "",
  ): Promise<Visitation[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", "visitations"]);
    if (action_id === "") {
      req.setQueryParameters({
        opt_code: opt_code,
        postal_code: postal_code,
        project_type: this.appMenu.currentApp,
      });
    } else {
      req.setQueryParameters({
        opt_code: opt_code,
        postal_code: postal_code,
        project_type: this.appMenu.currentApp,
        action_id: action_id,
      });
    }
    req.setHeaders(CONTENT_TYPE.JSON);

    if (error_callback)
      return this.apiHeatbrainService.get_public<Visitation[]>(req, (e) =>
        error_callback(e),
      );
    else
      return this.apiHeatbrainService.get_public<Visitation[]>(
        req,
        this.alert_on_error,
      );
  }

  public get_energieklus_project_visitee_by_optcode_postcode(
    opt_code: string,
    postal_code: string,
  ): Promise<string | null> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", "visitees/"]);
    req.setQueryParameters({
      postal_code: postal_code,
      opt_code: opt_code,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get_public(req, () => {
      return null;
    });
  }

  public post_start_visitation_now(visitee_id: string) {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "energieklus",
      "projects",
      "visitations",
      visitee_id,
      "start_now",
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public post_set_address_not_home(visitee_id: string): Promise<NotHome> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "energieklus",
      "projects",
      "visitations",
      visitee_id,
      "not_home",
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public delete_energieklus_visitations(project_id: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project_id, "visitations"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.delete(req, this.alert_on_error);
  }

  public cancel_energieklus_visitation_last_minute(
    visitee_id: string,
    reason: string = "sobolt",
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "energieklus",
      "projects",
      "visitations",
      visitee_id,
      "status",
    ]);
    req.setQueryParameters({
      reason: reason,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public put_buildings_gpkg(
    endpoint: string,
    key: string,
    project: Project,
    fileToUpload: File,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([endpoint, project.id]);
    // req.setQueryParameters({municipality_codes: mun_codes.join()});
    let obj = {};
    obj[key] = fileToUpload;
    req.setRequestBody(obj);
    req.setHeaders(CONTENT_TYPE.FORM);
    return this.apiHeatbrainService.put(req, this.alert_on_error);
  }

  private _sortProjectsOnProperty(property: string, ordering: string): void {
    if (ordering == "desc") {
      this.projects.sort((a, b) =>
        a[property].toString().toLowerCase() < b[property].toString().toLowerCase()
          ? 1
          : a[property].toString().toLowerCase() > b[property].toString().toLowerCase()
          ? -1
          : 0,
      );
    } else {
      this.projects.sort((a, b) =>
        a[property].toString().toLowerCase() > b[property].toString().toLowerCase()
          ? 1
          : a[property].toString().toLowerCase() < b[property].toString().toLowerCase()
          ? -1
          : 0,
      );
    }
  }

  public get_acquisition_stats(project_id: string): Promise<AcquisitionStats> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["get-acquisition-stats", project_id]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<AcquisitionStats>(req, (_) => {});
  }

  public get_sign_up_types(): Promise<any[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "types"]);
    req.setQueryParameters({ project_type: this.appMenu.currentApp });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<any[]>(req, this.console_on_error);
  }

  public async get_all_sign_up_questions(): Promise<any[]> {
    if (this.all_signup_questions != null) {
      return this.all_signup_questions;
    }
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "questions"]);
    req.setQueryParameters({
      project_type: this.appMenu.currentApp,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.all_signup_questions = await this.apiHeatbrainService.get<any[]>(
      req,
      this.console_on_error,
    );
    return this.all_signup_questions;
  }

  public async get_sign_up_questions_for_project(project_id: string): Promise<any[]> {
    if (
      this.selected_project &&
      this.selected_project.id == project_id &&
      this.signup_questions != null
    ) {
      return this.signup_questions;
    }
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "questions_for_project"]);
    req.setQueryParameters({
      project_type: this.appMenu.currentApp,
      project_id: project_id,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.signup_questions = await this.apiHeatbrainService.get<any[]>(
      req,
      this.console_on_error,
    );
    return this.signup_questions;
  }

  public async get_sign_up_type_for_project(project_id: string): Promise<any[]> {
    if (
      this.selected_project &&
      this.selected_project.id == project_id &&
      this.signup_types != null
    ) {
      return this.signup_types;
    }
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "type_for_project"]);
    req.setQueryParameters({
      project_id: project_id,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.signup_types = await this.apiHeatbrainService.get<any[]>(
      req,
      this.console_on_error,
    );
    return this.signup_types;
  }

  public get_scan_sign_up_type_for_project(project_id: string): Promise<any[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["scan", "sign_up", "type_for_project"]);
    req.setQueryParameters({
      project_id: project_id,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get<any[]>(req, this.console_on_error);
  }

  public async get_energieklus_sign_up_texts(
    project_id: string,
  ): Promise<{ [key: string]: string }> {
    if (
      this.selected_project &&
      this.selected_project.id == project_id &&
      this.signup_texts != null
    ) {
      return this.signup_texts;
    }
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "texts"]);
    req.setQueryParameters({ project_id: project_id });
    req.setHeaders(CONTENT_TYPE.JSON);
    this.signup_texts = await this.apiHeatbrainService.get_public<{
      [key: string]: string;
    }>(req, this.console_on_error);
    return this.signup_texts;
  }

  public set_sign_up_type(project_id: string, sign_up_type: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "type"]);
    req.setQueryParameters({
      project_id: project_id,
      sign_up_type: sign_up_type,
      project_type: this.appMenu.currentApp,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put(req, this.console_on_error);
  }

  public set_sign_up_questions(
    project_id: string,
    sign_up_questions: string,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "questions"]);
    req.setQueryParameters({
      project_id: project_id,
      sign_up_questions: sign_up_questions,
      project_type: this.appMenu.currentApp,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put(req, this.console_on_error);
  }

  public set_sign_up_texts(
    project_id: string,
    page_title: string,
    page_subtitle: string,
    sign_up_component_title: string,
    sign_up_instructions_title: string,
    sign_up_instructions_html: string,
    sign_up_faq_title: string,
    sign_up_faq_html: string,
    logo_url: string,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "texts"]);
    req.setQueryParameters({
      project_id: project_id,
      page_title: page_title,
      page_subtitle: page_subtitle,
      sign_up_component_title: sign_up_component_title,
      sign_up_instructions_title: sign_up_instructions_title,
      sign_up_instructions_html: sign_up_instructions_html,
      sign_up_faq_title: sign_up_faq_title,
      sign_up_faq_html: sign_up_faq_html,
      logo_url: logo_url,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.put(req, this.console_on_error);
  }

  public get_postcode_huisnummer(
    postcode: string,
    huisnummer: number,
    project_id: string = "",
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "full_address"]);
    req.setQueryParameters({
      postcode: postcode,
      huisnummer: huisnummer,
      project_type: this.appMenu.currentApp,
      project_id: project_id,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get_public(req, this.console_on_error);
  }

  public get_scan_postcode_huisnummer(
    postcode: string,
    huisnummer: number,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["scan", "sign_up", "full_address"]);
    req.setQueryParameters({ postcode: postcode, huisnummer: huisnummer });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get_public(req, this.console_on_error);
  }

  public get_sign_up_method_for_action(action_id: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "type"]);
    req.setQueryParameters({ action_id: action_id });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get_public(req, this.console_on_error);
  }

  public get_scan_sign_up_state_for_action(action_id: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["scan", "sign_up", "state"]);
    req.setQueryParameters({
      action_id: action_id,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get_public(req, this.alert_on_error);
  }

  public get_sign_up_questions_for_address(address_id: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "questions_for_address"]);
    req.setQueryParameters({ address_id: address_id });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get_public(req, this.console_on_error);
  }

  public get_sign_up_contact_rules_for_address(address_id: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "sign_up", "contact_rules_for_address"]);
    req.setQueryParameters({ address_id: address_id });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get_public(req, this.console_on_error);
  }

  public get_scan_sign_up_questions_for_address(address_id: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["scan", "sign_up", "questions_for_address"]);
    req.setQueryParameters({
      address_id: address_id,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get_public(req, this.console_on_error);
  }

  public updatePermissions(
    project_id: string,
    user: string,
    role: string,
  ): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project_id, "permissions"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setRequestBody({
      user: user,
      role: role,
    });
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public addPermissions(project_id: string, user: any): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "projects", project_id, "permissions", "new"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setRequestBody(user);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public deleteAllPermissions(project_id: string, user: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "energieklus",
      "projects",
      project_id,
      "permissions",
      "delete",
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setRequestBody(user);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public get_reports_on_bucket(project: Project): Promise<{ [key: string]: any }[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["reports-on-bucket"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setRequestBody(project);
    return this.apiHeatbrainService.post<{ [key: string]: any }[]>(
      req,
      this.alert_on_error,
    );
  }

  public create_signed_url(project: Project, gc_blob_path: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["create-signed-url"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setQueryParameters({ gc_blob_path: gc_blob_path });
    req.setRequestBody(project);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public generate_report_zip(project: Project, gc_blob_path: string): Promise<any> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["generate-report-zip"]);
    req.setHeaders(CONTENT_TYPE.JSON);
    req.setQueryParameters({ gc_blob_path: gc_blob_path });
    req.setRequestBody(project);
    return this.apiHeatbrainService.post(req, this.alert_on_error);
  }

  public get_activity_feed_total_tickets(
    project_name: string,
    ticket_type: string,
    ticket_status: string,
    daterange_start: Date,
    daterange_end: Date,
  ): Promise<{ count: number }[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "data", "activity_feed_total_tickets"]);
    req.setQueryParameters({
      filter_project_name: project_name,
      filter_ticket_type: ticket_type,
      filter_ticket_status: ticket_status,
      filter_daterange_start: daterange_start,
      filter_daterange_end: daterange_end,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_activity_feed(
    project_name: string,
    ticket_type: string,
    ticket_status: string,
    daterange_start: Date,
    daterange_end: Date,
  ): Promise<MessagebirdTicket[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "data", "activity_feed"]);
    req.setQueryParameters({
      filter_project_name: project_name,
      filter_ticket_type: ticket_type,
      filter_ticket_status: ticket_status,
      filter_daterange_start: daterange_start,
      filter_daterange_end: daterange_end,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_activity_feed_paginate(
    direction: string,
    ticket_time: Date,
    page_size: number,
    project_name: string,
    ticket_type: string,
    ticket_status: string,
    daterange_start: Date,
    daterange_end: Date,
  ): Promise<MessagebirdTicket[]> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters(["energieklus", "data", "activity_feed_paginate"]);
    req.setQueryParameters({
      pagination_direction: direction,
      pagination_last_ticket_time: ticket_time,
      pagination_page_size: page_size,
      filter_project_name: project_name,
      filter_ticket_type: ticket_type,
      filter_ticket_status: ticket_status,
      filter_daterange_start: daterange_start,
      filter_daterange_end: daterange_end,
    });
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }

  public get_messagebird_messages_by_conversation_id(
    messagebird_conversation_id: string,
  ): Promise<{ items: MessagebirdMessage[] }> {
    let req = this.apiHeatbrainService.requestFactory();
    req.setUrlParameters([
      "messagebird",
      "conversations",
      messagebird_conversation_id,
      "messages",
    ]);
    req.setHeaders(CONTENT_TYPE.JSON);
    return this.apiHeatbrainService.get(req, this.alert_on_error);
  }
}
